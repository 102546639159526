// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("../../../semantic/dist/semantic.min.js")


import moment from 'moment';
import JQuery from 'jquery';
var today = new moment().utcOffset("-05:00");

window.$ = window.jQuery = JQuery;

document.addEventListener("turbolinks:load", function () {

  $(".entry-date").calendar({...calendarOptions, maxDate});
  $(".event-date").calendar(calendarOptions);
  $('.ui.dropdown').dropdown();
  $('.ui.checkbox').checkbox();
  membershipOldImageBind();
  EntryTemperatureNumPad();

});
const maxDate = new Date(today.toDate().getFullYear(), today.toDate().getMonth(), today.toDate().getDate());
const calendarOptions = {
  type: 'date',
  text: {
    days: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Augosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthsShort: ['Ene', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    now: 'Ahora',
    am: 'AM',
    pm: 'PM'
  },
  formatter: {
    date: function (date, settings) {
      if (!date) return '';
      let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = String(date.getFullYear()).padStart(4, '0');
      return day + '/' + month + '/' + year;
    }
  }
}


function membershipOldImageBind() {
  if ($('.membership .toggle-image').is('*')) {
    $('input[type="checkbox"]').on('change', function () {
      let image = $(this).closest('div.inline.field').next('a.ui.image');
      image.toggle();
    });
  }
}

function EntryTemperatureNumPad() {
  if ($('.numpad-picker').is('*')) {
    const numberPadFields = $(".numpad-picker .number-pad-field");
    const digitFormat = /^\d+(\.\d+)?$/;
    $('.numpad-buttons a').on('click', function (e) {
      const numberPadField = $(this).closest(".numpad-picker").find(".number-pad-field");
      const errorLabel = $(this).closest(".numpad-picker").find(".numpad-text .ui.pointing.below.red.inverted.basic.label");
      e.preventDefault()
      const buttonValue = String($(this).data("value"));
      switch (buttonValue) {
        case "submit":
          if (numberPadField.val() == "") {
            errorLabel.html(errorLabel.data("empty"))
            errorLabel.show();
          }
          else if (!digitFormat.test(numberPadField.val())){
            errorLabel.html(errorLabel.data("invalid"))
            errorLabel.show();
          }
          else{
            $('.numpad-picker').closest("form").trigger("submit");
          }
          break;
        case "delete":
          let numberValue = numberPadField.val();
          numberPadField.val(numberValue.slice(0, -1))
          break;
        case "clear":
          numberPadField.val("")
          break;
        default:
          let nextValue = numberPadField.val() + buttonValue;
          numberPadField.val(nextValue);
      }
      numberPadField.trigger('change');
    });
    numberPadFields.on('change', function () {
      const numpadText = $(this).closest(".numpad-picker").find(".numpad-text .ui.message p");
      if ($(this).val() == ""){
        numpadText.html($(this).data("label"));
      }
      else{
        numpadText.html($(this).val());
      }
    });
  }
}
